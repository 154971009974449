import React, { useState, useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const LatestProducts = () => {

    const [products, setProducts] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('http://localhost:3002/api/products');
                // console.log('fetched products', response.data);
                setProducts(response.data);
            }

            catch (err) {
                console.log('Failed to fetch products', err.response.data);
            }
        };

        fetchProducts();
    }, []);


  return (
    <>
                  <Swiper
              spaceBetween={30}
              slidesPerView={3}
              onSwiper={(swiper) => console.log(swiper)}
              modules={[Autoplay]}
              loop={true}
              autoplay={{
                delay: 2000,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  // width: 768,
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                992: {
                  slidesPerView: 3,
                },
              }}
            >


{
                            products.length === 0 ? (<p>No Products available yet.</p>) :
                            (
                                    products.map(product =>
                                        (
                                            <SwiperSlide>
                                            <div className="shop-product h-auto cursor-pointer" onClick={()=> navigate('/shop')}>
                                              <img
                                                src={product.image}
                                                className="img-fluid h-auto"
                                                alt="Latest Product"
                                              />
                                              <h6 className="mt-3">{product.name}</h6>
                                              {/* <p>Category: {product.category}</p>
                                              <p>Stock: {product.stock}</p>
                                              <p>Description: {product.description}</p>
                                              <p>Price:&nbsp;
                                                <span className="dis-price">{product.discount_price}</span>
                                                <span className="org-price">{product.original_price}</span>
                                              </p>
                                              <button className="add-cart">Add to Cart</button> */}
                                            </div>
                                          </SwiperSlide>                                    )
                                )
                                )}

            </Swiper>

    </>
  )
}

export default LatestProducts
