import React, { useState } from 'react'
// import Login from './Login';
import axios from 'axios';

const Signup = ({ setShowSignup, setShowLogin }) => {
    // const [showLogin, setShowLogin] = useState(false);
    const [error, setError] = useState('');

    const [signinData, setSigninData] = useState({
        username: '',
        phone: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSigninData({ ...signinData, [name]: value });
    };

    const handleSignin = async (e) => {
        e.preventDefault();

        // Basic validation for phone number and password
        if (!/^\d+$/.test(signinData.phone)) {
            setError('Phone number must be numeric');
            return;
        }
        
        if (signinData.password.length < 6) {
            setError('Password must be at least 6 characters long');
            return;
        }

        try {
            await axios.post('http://localhost:3002/api/clients', signinData, {
                headers: {
                    'Content-Type' : 'application/json',
                },
            });

            setShowSignup(false);
            setShowLogin(true);
            alert('Signin Successful. Login to make purchase');
            setSigninData({username: '', phone: '', password: ''});
        }
        catch (error) {
            console.log('Oops! Cannot do signup', error.response || error.message);
                setError(error.response?.data || 'Cannot Do Signup. Try again later.');
        }
    };

    return (
        <>

{/* onClick={() => setShowSignup(true)}
onClick={(e) => e.stopPropagation()} */}

            <section className='py-5 bg-light'>
                <div className='login-inner'>

                    <h4>Complete your Sign In Here!</h4>

                    <i class="fa-solid fa-user-tie user-login-icon mt-4"></i>

                    <form className='my-4 text-start' onSubmit={handleSignin}>
                        <div className="col-12">
                            <label htmlFor="inputName3">Username</label>
                            <input type="text" name='username' value={signinData.username} onChange={handleChange} className="login-input" required />
                        </div>

                        <div className="col-12">
                            <label htmlFor="inputName3">Phone</label>
                            <input type="text" name='phone' minLength={10} maxLength={10} value={signinData.phone} onChange={handleChange} className="login-input" required />
                        </div>

                        <div className="col-12">
                            <label htmlFor="inputPassword3">Password</label>
                            <input type="password" name='password' value={signinData.password} onChange={handleChange} className="login-input" required />
                        </div>

                        {error && <div className="error-message">{error}</div>}
                        <button type="submit" className="login-btn mt-4">Sign In</button>

                        
                    </form>
                    {/* form ends */}
                    <h6 className='text-center'>Already have an account? <span className='toggle-link' onClick={() => {setShowSignup(false); setShowLogin(true)}}>Login</span> here</h6>

                </div>
            </section>

            {/* {showLogin && <Login setShowLogin={setShowLogin} />} */}
        </>
    )
}

export default Signup
