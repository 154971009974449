import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../assets/logo.png';
import AddProduct from './AddProduct';

const AdminHeader = ({ userDetails }) => {

  const [showAddProduct, setShowAddProduct] = useState(false);

  const navigate = useNavigate();

  const logoutUser = () => {
    localStorage.removeItem('user');
    navigate('/');
    window.location.reload();
  }


  return (
    <>

      {/* Header Section */}
      <section className='header-bg'>
        <div className='container d-flex justify-content-between'>

          <div className='d-inline-flex'>
            <p className='mobile-hide mt-3'><i className='fa-regular fa-envelope header-icon'></i>info@dectwincam.com</p>&nbsp;&nbsp;&nbsp;
            <p className='mobile-hide mt-3'><i className='fa-solid fa-phone-flip header-icon'></i>9454722998</p>

            <div className='mobile-show mt-2 mb-1'>
              <i className="fa-brands nav-icon fa-facebook-f"></i>
              <i className="fa-brands nav-icon fa-instagram"></i>
              <i className="fa-brands nav-icon fa-youtube"></i>
            </div>

          </div>

          <div className='text-light mt-3 text-capitalize'>
            Hello! {userDetails.username}</div>
        </div>
      </section>
      {/* Header Section ends */}

      {/* Navbar section */}
      <section className='sticky-top bg-white'>
        <div className='container d-flex justify-content-between'>

          <div>
            <Link><img src={Logo} className='shop-header-logo' alt='Navbar logo' /></Link>
          </div>

          <div className='d-flex mt-lg-4 my-3'>
            <h6 className='nav-text mt-1' onClick={() => navigate('/accounts/admin')}>My Store<i class="fa-solid fa-store ms-2"></i></h6>

            <div className='admin-user-outer'>
              <button className='nav-text bg-white border-0'>Admin <i className="fa-solid fa-user"></i></button>
              <div className='admin-user'>
                <h6 className='nav-text' onClick={() => setShowAddProduct(true)} >Add Product <i className="fa-solid fa-plus ms-2"></i></h6>
                <h6 className='nav-text mt-3' onClick={() => navigate('/accounts/admin/trackorders')}>Track Orders <i class="fa-solid fa-bag-shopping ms-2"></i></h6>
                <h6 className='nav-text mt-3'>Site Settings <i className="fa-solid fa-gear ms-2"></i></h6>
                <h6 className='nav-text mt-3' onClick={logoutUser}>Log out <i className="fa-solid fa-right-from-bracket ms-2"></i></h6>
              </div>
            </div>
            {/* admin user outer ends */}

          </div>
          {/* flex ends */}


        </div>
      </section>
      {/* Navbar section ends */}

      {showAddProduct && <AddProduct setShowAddProduct={setShowAddProduct} />}

    </>
  )
}

export default AdminHeader
