import React from 'react'
import AboutMe from '../general/AboutMe';
import LatestProducts from '../general/LatestProducts';
import Banner1 from '../assets/banner1.png';
import Banner2 from '../assets/banner2.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import Gallery1 from '../assets/gallery/1.jpg';
import Gallery2 from '../assets/gallery/2.jpg';
import Gallery3 from '../assets/gallery/3.jpg';
import Gallery4 from '../assets/gallery/4.jpg';
import Gallery5 from '../assets/gallery/5.jpg';
import Counter from '../general/Counter';
import ScrollAnimation from 'react-animate-on-scroll';


const Home = () => {

  return (
    <>

      {/* Slider Section */}
      <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" data-bs-interval="2000">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={Banner1} className="d-block w-100" alt="banner" />
          </div>
          <div className="carousel-item">
            <img src={Banner2} className="d-block w-100" alt="banner" />
          </div>
        </div>
        <button className="carousel-control-prev slider-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <i class="fa-solid text-danger fa-angle-left"></i>
        </button>

        <button className="carousel-control-next slider-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <i class="fa-solid text-danger fa-angle-right"></i>
        </button>
      </div>
      {/* Slider Section ends */}


      {/* key points section */}
      <section className='keypoint-bg'>
        <div className='container px-3'>

          <div className='row'>

            <div className='col-lg-4 col-md-6 col-12 my-2'>
              <div className='keypoint-area bg-dark'>
                <div className='d-flex justify-content-between'>
                  <i class="fa-solid fa-house-circle-check keypoint-icon"></i>
                  <p className='keypoint-num'>01</p>
                </div>
                {/* flex ends */}
                <h5 className='fw-bold'>Home Security</h5>
                <hr className='keypoint-hr' />
                <p>Effective home security combines physical deterrents, advanced technology, and smart monitoring solutions.</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12 my-2'>
              <div className='keypoint-area bg-dark'>
                <div className='d-flex justify-content-between'>
                  <i class="fa-solid fa-shield-halved keypoint-icon"></i>
                  <p className='keypoint-num'>02</p>
                </div>
                {/* flex ends */}
                <h5 className='fw-bold'>Information Security</h5>
                <hr className='keypoint-hr' />
                <p>Safeguarding the sensitive data through robust encryption, access controls, and regular audits of system.</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12 my-2'>
              <div className='keypoint-area bg-dark'>
                <div className='d-flex justify-content-between'>
                  <i class="fa-solid fa-headset keypoint-icon"></i>
                  <p className='keypoint-num'>03</p>
                </div>
                {/* flex ends */}
                <h5 className='fw-bold'>Quality Support</h5>
                <hr className='keypoint-hr' />
                <p>Installation guidance, and troubleshooting assistance, ensuring optimal performance and reliability.</p>
              </div>
            </div>


          </div>
          {/* row ends */}

        </div>
      </section>
      {/* key points section ends */}

      <AboutMe />

      {/* Services Section */}
      <section className='py-5 bg-light'>
        <div className='container px-4'>

          <ScrollAnimation animateIn='fadeIn' duration={2} className='row'>

            <div className='col-lg-4 col-md-6 col-12 py-4'>
              <hr className='sec-hr' />
              <div className='sec-heading'>Explore our Security range</div>
            </div>

            <div className='col-lg-4 col-md-6 col-12 cctv-area px-4 py-4'>
              <i class="fa-solid fa-fingerprint keypoint-icon"></i>
              <h5 className='fw-bold my-3'>Finger Print Access</h5>
              <p>Fingerprint access for CCTV cameras enhances security by allowing only authorized users to view footage, ensuring that sensitive information is protected from unauthorized access.</p>
              <i class="fa-solid fa-star cctv-icon"></i>
            </div>

            <div className='col-lg-4 col-md-6 col-12 cctv-area px-4 py-4'>
              <i class="fa-solid fa-camera-retro keypoint-icon"></i>
              <h5 className='fw-bold my-3'>Closed Circuit Cameras</h5>
              <p>Closed circuit cameras provide real-time monitoring and recording of specific areas, enhancing security by transmitting video feeds to designated monitors without public access.</p>
              <i class="fa-solid fa-star cctv-icon"></i>
            </div>

            <div className='col-lg-4 col-md-6 col-12 cctv-area px-4 py-4'>
              <i class="fa-solid fa-gears keypoint-icon"></i>
              <h5 className='fw-bold my-3'>CCTV Installation</h5>
              <p>Closed circuit cameras provide real-time monitoring and recording of specific areas, enhancing security by transmitting video feeds to designated monitors without public access.</p>
              <i class="fa-solid fa-star cctv-icon"></i>
            </div>

            <div className='col-lg-4 col-md-6 col-12 cctv-area px-4 py-4'>
              <i class="fa-brands fa-connectdevelop keypoint-icon"></i>
              <h5 className='fw-bold my-3'>HD Network IP Camera</h5>
              <p>Closed circuit cameras provide real-time monitoring and recording of specific areas, enhancing security by transmitting video feeds to designated monitors without public access.</p>
              <i class="fa-solid fa-star cctv-icon"></i>
            </div>

            <div className='col-lg-4 col-md-6 col-12 cctv-area px-4 py-4'>
              <i class="fa-solid fa-ethernet keypoint-icon"></i>
              <h5 className='fw-bold my-3'>Security CCTV Camera</h5>
              <p>Closed circuit cameras provide real-time monitoring and recording of specific areas, enhancing security by transmitting video feeds to designated monitors without public access.</p>
              <i class="fa-solid fa-star cctv-icon"></i>
            </div>


          </ScrollAnimation>
          {/* row ends */}

        </div>
      </section>
      {/* Services Section Ends */}


      {/* Gallery Section */}
      <section className='py-4 px-3'>

        <Swiper
          spaceBetween={30}
          slidesPerView={4}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
          modules={[Autoplay]}
          loop={true}
          autoplay={{
            delay: 2000
          }}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            768: {
              // width: 768,
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 4,
            }
          }}
        >


          <SwiperSlide>
            <div className='gallery-area'>
              <img src={Gallery1} className='img-fluid' alt='gallery' />
              <div className='gallery-overlay'>
                <hr className='sec-hr' />
                <h5>Security & Safety</h5>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='gallery-area'>
              <img src={Gallery2} className='img-fluid' alt='gallery' />
              <div className='gallery-overlay'>
                <hr className='sec-hr' />
                <h5 className='fw-bold'>Security & Safety</h5>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='gallery-area'>
              <img src={Gallery3} className='img-fluid' alt='gallery' />
              <div className='gallery-overlay'>
                <hr className='sec-hr' />
                <h5 className='fw-bold'>Security & Safety</h5>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='gallery-area'>
              <img src={Gallery4} className='img-fluid' alt='gallery' />
              <div className='gallery-overlay'>
                <hr className='sec-hr' />
                <h5 className='fw-bold'>Security & Safety</h5>
              </div>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className='gallery-area'>
              <img src={Gallery5} className='img-fluid' alt='gallery' />
              <div className='gallery-overlay'>
                <hr className='sec-hr' />
                <h5 className='fw-bold'>Security & Safety</h5>
              </div>
            </div>
          </SwiperSlide>


        </Swiper>

      </section>
      {/* Gallery Section ends */}

      <Counter />

      {/* Latest news section */}
      <section className='my-5'>
        <div className='container text-center px-3'>

          <hr className='sec-hr mx-auto' />
          <div className='sec-heading mb-4'>Latest Products & Updates</div>

          <LatestProducts />
          {/* <div className='row mt-5 text-start'>

            <div className='col-lg-4 col-md-6 col-12 my-2'>
              <div className='blog-area'>
                <img src={Gallery2} className='img-fluid' alt='Blog' />
                <h5 className='px-3 py-3'>CCTV Camera</h5>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12 my-2'>
              <div className='blog-area'>
                <img src={Gallery3} className='img-fluid' alt='Blog' />
                <h5 className='px-3 py-3'>CCTV Camera</h5>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12 my-2'>
              <div className='blog-area'>
                <img src={Gallery4} className='img-fluid' alt='Blog' />
                <h5 className='px-3 py-3'>CCTV Camera</h5>
              </div>
            </div>


          </div> */}
          {/* row ends */}

        </div>
      </section>
      {/* Latest news section ends */}

    </>
  )
}

export default Home;
