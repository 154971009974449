import React from 'react'
import { Link } from 'react-router-dom'

const SubHeader = (props) => {
    return (
        <>
            <section className="subheader">
                <div className="container">
                    <h2 className='mt-4 subheader-heading'>{props.message}</h2>
                    <Link className='text-light text-decoration-none' to='/'>Home</Link><span className='px-3'>|</span><span>{props.message}</span>
                </div>
            </section>

        </>
    )
}

export default SubHeader;
