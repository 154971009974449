import React, { useState } from 'react'

const AddtoCart = ({ selectedProduct, removeCart, onQuantityChange }) => {

    const [num, setNum] = useState(selectedProduct.quantity || 1);

    const increment = () => {
        const newQuantity = num + 1;
        setNum(newQuantity);
        onQuantityChange(selectedProduct.id, newQuantity);
    };

    const decrement = () => {
        const newQuantity = num > 1 ? num - 1 : 1;
        setNum(newQuantity);
        onQuantityChange(selectedProduct.id, newQuantity);
    };

    const totalDiscount = selectedProduct.discount_price * num;

    return (
        <>
            <section className='cart-product-outer'>
                <div className='row'>
                    <div className='col-lg-4 col-4 my-2'>
                        <img src={selectedProduct.image} className='img-fluid' alt='Cart Product' /></div>

                    <div className='col-lg-8 col-8'>
                        <p className='para-text my-1'>{selectedProduct.name}</p>
                        <p className='para-text my-1'>Price:&nbsp;
                            <span className="dis-price">{totalDiscount}</span>
                            <span className="org-price">{selectedProduct.original_price}</span>
                        </p>

                        <div className='d-flex flex-wrap'>

                            <div className='my-1'>
                                <button className='operator-sign' onClick={decrement}>-</button>
                                <span className='px-3'>{num}</span>
                                <button className='operator-sign' onClick={increment}>+</button>
                            </div>

                            <button className='remove-cart ms-auto my-1' onClick={() => removeCart(selectedProduct.id)}>Remove <i class="fa-solid fa-xmark"></i></button>
                        </div>
                        {/* flex ends */}

                    </div>
                </div>
            </section>
        </>
    )
}

export default AddtoCart
