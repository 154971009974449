import React from 'react'
import SubHeader from '../general/SubHeader'

const Faq = () => {
    return (
        <>

            <SubHeader message='FAQs' />

            <section className='my-5'>
                <div className='container'>

                    <hr className='sec-hr' />
                    <div className='sec-heading'>Frequently Asked Questions</div>

                    <p className='my-4'><strong>1. What types of CCTV cameras do you offer?</strong><br />
                        We provide a wide range of CCTV cameras, including dome, bullet, and wireless cameras, suitable for homes,
                        offices, and industrial settings. Our cameras are compatible with mobile and desktop apps, allowing you to monitor your premises from anywhere in real time.</p>

                    <p className='my-4'><strong>2. Do you offer installation services?</strong><br />
                        Yes, we provide professional installation services to ensure your CCTV cameras are set up correctly for maximum
                        coverage and security. We also offer regular maintenance packages to keep your cameras and systems functioning
                        efficiently, including updates and troubleshooting.</p>

                    <p className='my-4'><strong>3. What accessories do you provide for CCTV cameras?</strong><br />
                        We supply DVRs, NVRs, cables, power supplies, and mounts to support seamless installation and operation of your CCTV systems.</p>

                    <p className='my-4'><strong>4. Do you provide delivery services for CCTV cameras and accessories?</strong><br />
                        Yes, we offer reliable delivery services for all our products. Whether you order cameras, accessories, or both,
                        we ensure timely and secure delivery to your doorstep. Delivery typically takes 3-7 business days, depending on your location. For remote areas,
                        delivery timelines may vary slightly.</p>

                    <p className='my-4'><strong>5. How can I get assistance or answers to my queries about your products?</strong><br />
                        You can reach out to us via our customer support number, email, or the raise query option on our website. Our team is available to help with
                        product details, compatibility, installation advice, and any other questions you may have. We aim to provide prompt and clear responses to
                        ensure your satisfaction.</p>

                </div>
            </section>
        </>
    )
}

export default Faq
