import React, {useState} from 'react'
import Login from './Login';
import Signup from './Signup';

const Accounts = () => {

    const [showLogin, setShowLogin] = useState(true);

    // useEffect(() => {
    //     setShowLogin(true);
    // }, []);

    return (
        <>

{showLogin ? ( <Login setShowLogin={setShowLogin} /> ) : ( <Signup setShowSignup={setShowLogin} setShowLogin={setShowLogin} /> )}

        </>
    );
};

export default Accounts;
