import React, { useState } from 'react'
import SubHeader from '../general/SubHeader';
import ScrollAnimation from 'react-animate-on-scroll';
import axios from 'axios';
import MapImg from '../assets/map-img.jpg';

const Contact = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    mobile: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3002/api/contact', formData);
      alert('Form Submission Successful!');
    }
    catch (error) {
      console.error('Oops! There is an error while submitting the form', error);
    }
  };

  return (
    <>

      <SubHeader message='Contact' />

      {/* Map Section */}

      <section>
        <div className='row'>

          <div className='col-lg-6 col-12 px-0 py-0'>
            <img src={MapImg} alt='contact' className='img-fluid' />
          </div>

          <div className='col-lg-6 col-12 px-0 py-0'>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3603.3091580224054!2d81.9153903752661!3d25.42792567756324!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39854b6b9fb57d89%3A0x271cde0afb4b914c!2sOpp.%20Nyaya%20nagar%20gate%20jhunsi!5e0!3m2!1sen!2sin!4v1730109659960!5m2!1sen!2sin"
              className='w-100 h-100' style={{ border: '0' }} title='Map Section' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

        </div>
      </section>
      {/* map section ends */}


      {/* Details section */}
      <section className='detail-bg'>
        <div className='container px-3'>

          <div className='row justify-content-center'>

            <div className='col-lg-4 col-md-6 col-12 my-2 d-flex'>
              <i class="fa-regular fa-map detail-icon"></i>
              <div>
                <h5 className='text-red'>Office Address</h5>
                <p>Opp nyay nagar gate, jhunsi, prayagraj, 211019</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12 my-2 d-flex'>
              <i class="fa-regular fa-clock detail-icon"></i>
              <div>
                <h5 className='text-red'>Working Hours</h5>
                <p>Monday to Saturday
                  10:00 AM - 8:00 PM</p>
              </div>
            </div>

            <div className='col-lg-4 col-md-6 col-12 my-2 d-flex'>
              <i class="fa-regular fa-envelope detail-icon"></i>
              <div>
                <h5 className='text-red'>Message Us</h5>
                <p>info@dectwincam.com</p>
              </div>
            </div>


          </div>
          {/* row ends */}

        </div>
      </section>
      {/* Details section ends */}


      {/* Form Section */}
      <section className='bg-light py-5'>
        <div className='container px-3 text-center'>

          <hr className='sec-hr mx-auto' />
          <div className='sec-heading'>Get In Touch</div>

          <ScrollAnimation animateIn='zoomIn' duration={2}>
            <form class="row g-3 mt-4" onSubmit={handleSubmit}>
              <div class="col-md-6">
                <input type="text" class="contact-input" id="inputText4" name='name' value={formData.name} onChange={handleChange} placeholder='Name' required />
              </div>
              <div class="col-md-6">
                <input type="email" class="contact-input" id="inputEmail4" name='email' value={formData.email} onChange={handleChange} placeholder='Email' required />
              </div>
              <div class="col-md-6">
                <input type="text" class="contact-input" id="inputText4" name='subject' value={formData.subject} onChange={handleChange} placeholder='Subject' required />
              </div>
              <div class="col-md-6">
                <input type="tel" class="contact-input" id="inputText4" name='mobile' value={formData.mobile} onChange={handleChange} placeholder='Mobile Number' maxLength={10} required />
              </div>
              <div class="col-12">
                <textarea type="text" class="contact-input" id="inputText4" name='message' value={formData.message} onChange={handleChange} placeholder='Message' style={{ height: '12em' }}></textarea>
              </div>
              <div class="col-12">
                <button type="submit" class="header-link border-0 text-light rounded">Submit Now</button>
              </div>
            </form>
          </ScrollAnimation>

        </div>
      </section>
      {/* Form Section ends */}

    </>
  )
}

export default Contact
