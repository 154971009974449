import React from 'react'
import SubHeader from '../general/SubHeader';
import AboutMe from '../general/AboutMe';
import Counter from '../general/Counter';
import TestimonialImg from '../assets/testimonial-img2.png';
import Default from '../assets/default-img.png';

const About = () => {
  return (
    <>
      <SubHeader message='About' />

      <AboutMe />
      <Counter />

      {/* Testimonial Section */}
      <section>

        <div className='row'>

          <div className='col-lg-6 col-12 my-5 px-lg-5 px-4'>
            <hr className='sec-hr' />
            <div className='sec-heading'>What our Client Say</div>

            <div id="carouselExampleIndicators" className="carousel slide mt-5 carousel-fade" data-bs-ride="carousel" data-bs-interval="2000">
              <div className="carousel-indicators">
                <button type="button" className='ind-btn active' data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
                <button type="button" className='ind-btn' data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                <button type="button" className='ind-btn' data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <div className='testimonial-area'>
                    <p className='fst-italic'>The image quality is fantastic, even at night, and the app is user-friendly. Installation was straightforward, and the customer support team was incredibly helpful when I had questions.</p>
                    <h6 className='mt-4'>- Our Client</h6>
                    <img src={Default} className='client-img' alt='Client' />
                  </div>
                </div>

                <div className="carousel-item">
                  <div className='testimonial-area'>
                    <p className='fst-italic'>I purchased Dectwin cameras for my small business, and they’ve been a game changer. The motion detection feature is precise, and I love being able to access the footage remotely.</p>
                    <h6 className='mt-4'>- Our Client</h6>
                    <img src={Default} className='client-img' alt='Client' />
                  </div>
                </div>

                <div className="carousel-item">
                  <div className='testimonial-area'>
                    <p className='fst-italic'>The video quality is excellent, and the installation was relatively easy. I’ve reached out to customer support, and they’re responsive. I want to love this product as much as everyone else seems to!"</p>
                    <h6 className='mt-4'>- Our Client</h6>
                    <img src={Default} className='client-img' alt='Client' />
                  </div>
                </div>

              </div>
              {/* carousel inner ends */}
            </div>
            {/* carousel ends */}

          </div>

          <div className='col-lg-6 col-12 order-first order-lg-last'>
            <img src={TestimonialImg} className='img-fluid' alt='Testimonial' />
          </div>


        </div>
        {/* row ends */}

      </section>
      {/* Testimonial Section ends */}

    </>
  )
}

export default About
