import React from 'react';
import Logo from '../assets/logo.png';
import { Link, useNavigate } from 'react-router-dom';
// import Cart from '../cart/Cart';
// import { useLocation } from 'react-router-dom';


const ShopHeader = ({ userDetails, setShowCart }) => {

    // const [showCart, setShowCart] = useState(false);
    const navigate = useNavigate();

    const logoutUser = () => {
        localStorage.removeItem('user');
        navigate('/');
        window.location.reload();
    }

    return (
        <>
            {/* Header Section */}

            <div id="carouselExampleControls" className="carousel slide bg-lightred" data-bs-ride="carousel" data-bs-interval="2000">
                <div className="carousel-inner text-center">
                    <div className="carousel-item active">
                        <p className='shop-header-text pt-2'>Upto 25% OFF on all Products</p>
                    </div>
                    <div className="carousel-item">
                        <p className='shop-header-text pt-2'>Latest camera range for you</p>
                    </div>
                    <div className="carousel-item">
                        <p className='shop-header-text pt-2'>7 days replacement policy</p>
                    </div>
                </div>
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                    <i className="fa-solid text-black fa-angle-left"></i>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                    <i className="fa-solid text-black fa-angle-right"></i>
                </button>
            </div>

            {/* Header Section ends */}


            {/* Navbar section */}
            <section className='sticky-top bg-white'>
                <div className='container d-flex justify-content-between'>

                    <div>
                        <Link><img src={Logo} className='shop-header-logo' alt='Navbar logo' /></Link>
                    </div>

                    <div className='mt-lg-4 my-3 d-flex'>
                        {/* <Link to='/' className='nav-text'>Home</Link> */}

                        <div>
                            <Link to='/accounts/shop' className='nav-text'>Shop</Link>
                        </div>

                        {/* <span className='fw-bold text-capitalize'>Hey! {userDetails.username}</span> */}

                        <div className='shop-user-outer'>
                            <i className="fa-regular shop-header-icon fa-user mx-3"></i>
                            <div className='shop-user'>
                                <h6 className='text-capitalize pb-1'>Username : {userDetails.username}</h6>
                                <h6 className='cursor-pointer pb-1' onClick={() => navigate('/accounts/shop/orders')}>Order : Order History</h6>
                                <h6 className='cursor-pointer' onClick={logoutUser}>Log out : <i className="fa-solid fa-right-from-bracket ms-3"></i></h6>
                            </div>
                        </div>

                        <div onClick={() => setShowCart(true)}>
                            <i className="fa-solid shop-header-icon fa-cart-shopping"></i></div>
                    </div>

                </div>
            </section>
            {/* Navbar section ends */}


            {/* {showCart && <Cart setShowCart={setShowCart} cart={cart} removeCart={removeCart} />} */}

        </>
    )
}

export default ShopHeader
