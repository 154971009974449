import React from 'react'
import AboutImg from '../assets/about-img.jpg';
import AboutImg2 from '../assets/about-img2.jpg';
import ScrollAnimation from 'react-animate-on-scroll';

const AboutMe = () => {
  return (
    <>
            {/* About Section */}
            <section className='my-5'>
        <div className='container px-3'>

          <div className='row'>

            <ScrollAnimation animateIn='slideInLeft' duration={2} className='col-lg-6 col-12 my-2'>
              <img src={AboutImg} alt='About' className='about-img img-fluid' />
              <img src={AboutImg2} alt='About' className='about-img2 float-end' />
            </ScrollAnimation>

            <ScrollAnimation animateIn='slideInRight' duration={2} className='col-lg-6 col-12 my-2'>
              <hr className='sec-hr' />
              <div className='sec-heading'>Best Solution for Security System</div>
              <p className='my-4 text-muted'>Dectwin Cam offers cutting-edge surveillance solutions designed to enhance security for homes and businesses. With high-definition video quality, advanced motion detection, and remote access capabilities, our cameras ensure peace of mind.<br />
                Our dedicated team of experts combines cutting-edge technology with a customer-centric approach, ensuring seamless integration and optimal performance. We are committed to delivering high-quality software that enhances efficiency, drives growth, and empowers our clients to achieve their goals.</p>
              <p className='my-4 text-muted'>Experience comprehensive security solutions in one place, backed by exceptional services and support. Your safety is our top priority.</p>

              <h5 className='fw-bold d-flex mb-4'><i className="fa-solid fa-check about-icon"></i><span className='py-1'>All your Security System at one place</span></h5>
              <h5 className='fw-bold d-flex mb-4'><i className="fa-solid fa-check about-icon"></i><span className='py-1'>Great Services & Support with us</span></h5>
              <h5 className='fw-bold d-flex mb-4'><i className="fa-solid fa-check about-icon"></i><span className='py-1'>Responsive Team to solve your queries</span></h5>


            </ScrollAnimation>


          </div>
          {/* row ends */}

        </div>
      </section>
      {/* About Section Ends */}

    </>
  )
}

export default AboutMe
