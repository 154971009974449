import React, {useState} from 'react'

const AddProduct = ({ setShowAddProduct }) => {

  const [formData, setFormData] = useState({
    name: "",
    category: "",
    stock: "",
    original_price: "",
    discount_price: "",
    description: "",
    image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      image: e.target.files[0], // Store the selected file
    }));
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();

    const form = new FormData();
    for (let key in formData) {
      form.append(key, formData[key]);
    }

    try {
      const response = await fetch("http://localhost:3002/api/products", {
        method: "POST",
        body: form,
      });

      if (response.ok) {
        alert("Product added successfully");
        setShowAddProduct(false); // Close form (optional)
        window.location.reload();
      } else {
        alert("Failed to add product");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred while adding the product.");
    }
  };



  return (
    <>
      <section className='py-5 bg-light'>
        <div className='login-inner'>

          <h4>Add new Products in your list!</h4>

          <form className='my-4 text-start row' onSubmit={handleAddProduct}>
            <div className="col-12">
              <label for="inputName3">Product Name</label>
              <input type="text" className="login-input" name='name' value={formData.name} onChange={handleChange} required />
            </div>

            <div className="col-md-6">
              <label for="inputCategory3">Select Category</label>
              <select className="login-input" name='category' value={formData.category} onChange={handleChange} required>
                <option className="login-input" value="" selected disabled>Choose from</option>
                <option className="login-input" value="HD Camera">HD Camera</option>
                <option className="login-input" value="IP Camera">IP Camera</option>
                <option className="login-input" value="DVR/ NVR">DVR/ NVR</option>
                <option className="login-input" value="POE Swtich/ SMPS">POE Swtich/ SMPS</option>
                <option className="login-input" value="Wires/ Cables">Wires/ Cables</option>
                <option className="login-input" value="Camera Accessories">Camera Accessories</option>
              </select>
            </div>

            <div className='col-md-6'>
            <label for="inputStock3">Select Stock</label>
              <select className="login-input" name='stock' value={formData.stock} onChange={handleChange} required>
                <option className="login-input" value="" selected disabled>Choose from</option>
                <option className="login-input" value="In Stock">In Stock</option>
                <option className="login-input" value="Out of Stock">Out of Stock</option>
              </select>
            </div>
  
            <div className="col-md-6">
              <label for="inputOrgPrice3">Original Price</label>
              <input type="number" className="login-input" name='original_price' value={formData.original_price} onChange={handleChange} required/>
            </div>

            <div className="col-md-6">
              <label for="inputDisPrice3">Discount Price</label>
              <input type="number" className="login-input" name='discount_price' value={formData.discount_price} onChange={handleChange} required />
            </div>

            <div className='col-12'>
              <label for="formFile" >Upload Product Image</label>
              <input class="login-input" type="file" id="formFile" name='image' onChange={handleFileChange} required />
            </div>

            <div className="col-12">
              <label for="inputDescription3">Description</label>
              <input type="text" className="login-input" style={{ height: '6em' }} name='description' value={formData.description} onChange={handleChange} required />
            </div>

            {/* Display error message if login fails */}
            {/* {error && <div className="error-message">{error}</div>} */}

            <div className='col-12'>
              <button type="submit" className="login-btn mt-4">Add New Product</button>
            </div>

          </form>
          {/* form ends */}


        </div>
      </section>
    </>
  )
}

export default AddProduct
