import React, { useState, useEffect } from 'react'
import axios from 'axios';

const Category = ({ userDetails, setShowCart, handleAddCart }) => {

    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [showFilter, setShowFilter] = useState(false); // Controls the visibility of the filter
    const [selectedCategory, setSelectedCategory] = useState(''); // Stores selected category
    const [sortOrder, setSortOrder] = useState(''); // Tracks the selected sort order


    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('http://localhost:3002/api/products');
                setProducts(response.data);
                setFilteredProducts(response.data); // Initially, show all products
                // Extract categories from the products data
                const uniqueCategories = [
                    ...new Set(response.data.map(product => product.category))
                ];
                setCategories(uniqueCategories);
            } catch (err) {
                console.log('Failed to fetch products', err.response ? err.response.data : err);
            }
        };

        fetchProducts();
    }, []);

    // Filter products based on the selected category
    const handleFilter = (category) => {
        setSelectedCategory(category);
        if (category === '') {
            setFilteredProducts(products); // Show all products if no category is selected
        } else {
            const filtered = products.filter(product => product.category === category);
            setFilteredProducts(filtered);
            handleSort(sortOrder, filtered);
        }
    };

    // Sort products by price
    const handleSort = (order, productsToSort = filteredProducts) => {
        setSortOrder(order);
        const sorted = [...productsToSort].sort((a, b) => {
            if (order === 'lowToHigh') return a.discount_price - b.discount_price;
            if (order === 'highToLow') return b.discount_price - a.discount_price;
            return 0; // Default: no sorting
        });
        setFilteredProducts(sorted);
    };


    return (
        <>
            <section className='bg-light py-5'>

                <div className="container d-flex justify-content-center">
                    <hr className="shop-hr" />
                    <h4 className="px-3 text-center text-nowrap">
                        Shop Like a Pro<h6>Explore through Categories!</h6>
                    </h4>
                    <hr className="shop-hr" />
                </div>

                <div className='px-3 my-4 fs-6 cursor-pointer' onClick={() => setShowFilter(true)}>
                    Filters <i className="fa-solid fa-filter"></i></div>


                {/* Filter Options */}
                {showFilter && (
                    <div className='filter-options'>
                        <button className='close-cart float-end' onClick={() => setShowFilter(false)}><i class="fa-solid fa-xmark"></i></button>
                        
                        <h5>Select Category:</h5>
                        <div className="filter-list mt-4">
                        <h6 className={`filter-item ${selectedCategory === '' ? 'active' : ''}`}  onClick={() => handleFilter('')}>All Categories</h6>
                            {categories.map((category, index) => (
                                <h6 key={index} className={`filter-item ${selectedCategory === category ? 'active' : ''}`} onClick={() => handleFilter(category)}>{category}</h6>
                            ))}
                        </div>

                        <h5 className='mt-4'>Sort Prices:</h5>

                        <h6 className={`filter-item ${sortOrder === 'lowToHigh' ? 'active' : ''}`}
                        onClick={() => handleSort('lowToHigh')}>Low to High <i class="fa-solid fa-arrow-up-wide-short"></i></h6>
                        <h6 className={`filter-item ${sortOrder === 'highToLow' ? 'active' : ''}`} 
                            onClick={() => handleSort('highToLow')}>High to Low <i class="fa-solid fa-arrow-down-wide-short"></i></h6>

                    </div>
                )}


                <div className='row px-3 row-cols-xl-4 row-cols-lg-3 row-cols-md-2 row-cols-2'>

                    {
                        filteredProducts.length === 0 ? (<p>No Products available yet.</p>) :
                            (
                                filteredProducts.map(product =>
                                (
                                    <div className='col mb-3'>
                                        <div className='shop-product h-auto'>
                                            <img
                                                src={product.image}
                                                className="img-fluid h-auto"
                                                alt="Latest Product"
                                            />
                                            <h5 className="shop-product-title my-3">{product.name}</h5>
                                            <p>Category: {product.category}</p>
                                            <p>Stock: {product.stock}</p>
                                            <p>Description: {product.description}</p>
                                            <p>Price:&nbsp;
                                                <span className="dis-price">{product.discount_price}</span>
                                                <span className="org-price">{product.original_price}</span>
                                            </p>
                                            <button className="add-cart" onClick={() => handleAddCart(product)}>Add to Cart</button>
                                        </div>
                                    </div>)
                                )
                            )}


                </div>
                {/* row ends */}

            </section>
        </>
    )
}

export default Category
