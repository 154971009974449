import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Header from './general/Header';
import Footer from './general/Footer';
import About from "./components/About";
import Shop from "./components/Shop";
import Contact from "./components/Contact";
import Accounts from "./shop/Accounts";
import Admin from "./general/Admin";
import Orders from "./cart/Orders";
import PageNotFound from "./components/PageNotFound";
import "animate.css";
import "animate.css/animate.compat.css";
import ScrollTop from "./general/ScrollTop";
import ShopHeader from "./shop/ShopHeader";
import Cart from "./cart/Cart";
import Copyright from "./general/Copyright";
import AdminOrders from "./cart/AdminOrders";
import AdminHeader from "./shop/AdminHeader";
import Category from "./components/Category";
import Faq from './components/Faq'
// import { type } from "@testing-library/user-event/dist/type";
// import { json } from "body-parser";

const App = () => {
  const userActive = localStorage.getItem("user");
  const userDetails = JSON.parse(userActive);

  const userId = parseInt(userDetails?.id);

  // Check if data is in local storage
  const savedCart = (() => {
    try {
      return JSON.parse(localStorage.getItem("cart")) || [];
    } catch (e) {
      console.error("Failed to parse cart from localStorage", e);
      return [];
    }
  })();

  const [showCart, setShowCart] = useState(false);
  const [cart, setCart] = useState(savedCart);

  // Update localStorage whenever the cart changes
  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, [cart]);

  // Function to handle adding product to the cart
  const handleAddCart = (product) => {
    setCart((prevCart) => {
      const productExists = prevCart.find((item) => item.id === product.id);

      if (productExists) {
        // If product exists, increase the quantity by 1
        return prevCart.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }

      // If product doesn't exist, add it to the cart with quantity 1
      return [...prevCart, { ...product, quantity: 1 }];
    });

    // Show cart once the product is added
    setShowCart(true);
  };

  // Function to handle removing product from the cart
  const handleRemoveCart = (productId) => {
    setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
  };

  // Function to handle updating product quantity in the cart
  const handleQuantityChange = (id, newQuantity) => {
    if (newQuantity <= 0) return; // Prevent setting a negative or zero quantity
    setCart((prevCart) =>
      prevCart.map((item) =>
        item.id === id ? { ...item, quantity: newQuantity } : item
      )
    );
  };


  return (
    <BrowserRouter>
      <ScrollTop />

      {showCart && (
        <Cart
          cart={cart}
          setShowCart={setShowCart}
          removeCart={handleRemoveCart}
          userDetails={userDetails}
          quantityChange={handleQuantityChange}
        />
      )}

      {!userDetails && (
        <>
        <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/accounts" element={<Accounts />} />
          <Route exact path="/contact" element={<Contact />} />
          <Route exact path="/faqs" element={<Faq />} />
          <Route exact path="/shop" element={<Shop  userDetails={userDetails}
                  setShowCart={setShowCart}
                  setCart={setCart}
                  handleAddCart={handleAddCart} />} />
          <Route exact path="/shop/category" element={<Category  userDetails={userDetails}
                  setShowCart={setShowCart}
                  setCart={setCart}
                  handleAddCart={handleAddCart} />} />
          <Route exact path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
        </>
      )}

      {userDetails && userId === 21 && (
        <>
          <AdminHeader userDetails={userDetails} />
          <Routes>
            <Route
              exact
              path="/"
              element={<Admin userDetails={userDetails} />}
            />
            <Route
              exact
              path="/accounts/admin"
              element={<Admin userDetails={userDetails} />}
            />

            <Route
              exact
              path="/accounts/admin/trackorders"
              element={
                <AdminOrders userDetails={userDetails} />
              }
            />

            <Route exact path="*" element={<PageNotFound />} />
          </Routes>
          <Copyright />
        </>
      )}

      {userDetails && userId !== 21 && (
        <>
          <ShopHeader userDetails={userDetails} setShowCart={setShowCart} />
          <Routes>
            <Route
              exact
              path="/"
              element={
                <Shop
                  userDetails={userDetails}
                  setShowCart={setShowCart}
                  setCart={setCart}
                  handleAddCart={handleAddCart}
                />
              }
            />
            <Route
              exact
              path="/accounts/shop"
              element={
                <Shop
                  userDetails={userDetails}
                  setShowCart={setShowCart}
                  setCart={setCart}
                  handleAddCart={handleAddCart}
                />
              }
            />

            <Route
              exact
              path="/shop/category"
              element={
                <Category
                  userDetails={userDetails}
                  setShowCart={setShowCart}
                  setCart={setCart}
                  handleAddCart={handleAddCart}
                />
              }
            />

            <Route
              exact
              path="/accounts/shop/orders"
              element={
                <Orders userDetails={userDetails} setShowCart={setShowCart} />
              }
            />
            <Route exact path="*" element={<PageNotFound />} />
          </Routes>
          <Copyright />
        </>
      )}
    </BrowserRouter>
  );
};

export default App;
