import React from 'react'
import ChooseImg from '../assets/about-img1.png';

const WhyChoose = () => {
  return (
    <>
      <section className='my-5'>
        <div className='container'>

<div className='row'>

<div className='col-lg-6 col-12'>
    <img src={ChooseImg} className='img-fluid' alt='Why Choose' />
</div>

<div className='col-lg-6 col-12 my-lg-5 mb-2 order-first order-lg-last'>
    <h2 className='mb-4'>Why choose us?</h2>
    <p><strong>◉ High Quality Imaging</strong>- Dectwin CCTV cameras feature advanced imaging technology, ensuring crystal-clear video quality even in low-light conditions. This means you can monitor your premises with confidence, capturing every detail.</p>
    <p><strong>◉ Quick & Easy Installation</strong>- Our cameras are designed for seamless installation and can be easily integrated into existing security systems. This user-friendly approach makes setting up your surveillance system hassle-free.</p>
    <p><strong>◉ Strong Security Features</strong>- Dectwin cameras come equipped with cutting-edge security features such as motion detection, remote access, and customizable alerts, allowing you to stay informed and in control of your property at all times.</p>
    <p><strong>◉ Smart Features</strong>- Our cameras come equipped with smart features such as motion detection, remote viewing, and real-time alerts, allowing you to monitor your property effortlessly and respond quickly to any incidents.</p>
</div>

</div>
{/* row ends */}

        </div>
      </section>
    </>
  )
}

export default WhyChoose;
