import React from 'react'
// import AdminHeader from '../shop/AdminHeader';
import AdminProducts from '../shop/AdminProducts';
import { useLocation } from 'react-router-dom';

const Admin = ({ userDetails }) => {

  const location = useLocation();
  userDetails = location.state;

  // const [showAddProduct, setShowAddProduct] = useState(false);

  return (
    <>
      {/* <AdminHeader userDetails={userDetails} /> */}

      <AdminProducts />

    </>
  )
}

export default Admin;
