import React, { useState, useEffect } from 'react'

const Orders = ({ userDetails }) => {

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Ensure userDetails and phone are available
    if (userDetails && userDetails.phone) {

      const phone = userDetails.phone;
      // Fetch orders based on phone number
      fetch(`http://localhost:3002/api/orders?phone=${phone}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setError(data.error);
          } else {
            const sortedOrders = data.orders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
            setOrders(sortedOrders); // Update state with sorted orders
            }
          setLoading(false); // Set loading to false after fetching data
        })
        .catch((err) => {
          setError("Error fetching orders");
          setLoading(false); // Set loading to false on error
        });
    }
  }, [userDetails]); // Re-run when userDetails changes

  if (loading) {
    return <div className='my-4 text-center'>Loading orders...</div>;
  }

  if (error) {
    return <div className='my-4 text-center'>{error}</div>;
  }


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB'); // For format "DD/MM/YYYY"
  };


   // Function to calculate the total amount for an order
   const calculateTotalAmount = (orderItems) => {
    return orderItems.reduce((total, item) => {
      return total + item.order_price; // Sum up quantity * price
    }, 0);
  };

  return (
    <>
      <section className='py-5 bg-light'>
        <div className='container'>

          <div className="d-flex justify-content-center">
            <hr className="shop-hr" />
            <h4 className="px-3 text-center text-nowrap">Order History</h4>
            <hr className="shop-hr" />  
          </div>

          <div className="order-list mt-5">
            {orders.length === 0 ? (
              <p>No orders found for this phone number.</p>
            ) : (
              orders.map((order) => (
                <div key={order.orderId} className="order-item my-4">
                  <h5>Order ID #00{order.orderId}</h5>

                  <button className='pay-btn float-end'>Raise Query <i className="fa-solid fa-hand"></i></button>

                  <p className='para-text text-muted mb-0'>Order Date: {formatDate(order.orderDate)}</p>
                  <p className='para-text text-muted text-capitalize'>Delivery Status: {order.status}</p>

                  <h6>Order Details:</h6>

                    <div className='table-responsive rounded-2'>
                      <table className='w-100 order-item-table'>
                        <tr>
                          <th>Product Name</th>
                          <th>Quantity</th>
                          <th>Price</th>
                        </tr>

                          {order.orderItems.map((item, index) => (
                            <tr key={index}>
                              <td>{item.order_name}</td>
                              <td>(Qty: {item.order_qty})</td>
                              <td>₹ {item.order_price}</td> 
                            </tr>
                          ))}

                      </table>
                    </div>
                    {/* table div ends */}

                    <h6 className='mt-2'>Order Total: {calculateTotalAmount(order.orderItems).toFixed(2)}</h6>

                </div>
              ))
            )}
          </div>


        </div>
      </section>
    </>
  )
}

export default Orders
