import React, { useEffect, useState } from 'react'
import '../Cart.css';
import AddtoCart from './AddtoCart'

const Cart = ({ cart, setShowCart, removeCart, userDetails, quantityChange }) => {

  const [totalPrice, setTotalPrice] = useState(0);

  // Function to calculate the total price of products in the cart
  const calculateTotalPrice = () => {
    const total = cart.reduce((acc, item) => {
      return acc + (item.discount_price * item.quantity);
    }, 0);
    setTotalPrice(total);
  };

  // Recalculate the total price when cart or product quantity changes
  useEffect(() => {
    calculateTotalPrice();
  }); // Recalculate total when cart changes


   // Handle order submission
   const handleOrder = () => {
    // Check if userDetails exists and contains a phone number
    if (!userDetails || !userDetails.phone) {
      alert("Log in first to complete your purchase.");
      return;
    }

    // Prepare order details for submission to backend
    const orderDetails = cart.map((item) => ({
      order_name: item.name,
      order_qty: item.quantity,
      order_price: item.discount_price * item.quantity,
      order_date: new Date().toISOString().split('T')[0], // Current date in YYYY-MM-DD format
      phone: userDetails.phone,
    }));

    // Send order details to backend (replace with your actual API endpoint)
    fetch('http://localhost:3002/api/orders', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        orders: orderDetails,
        total_price: totalPrice,
        phone: userDetails.phone,
      }),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log("Order placed successfully:", data);
      alert("Order placed successfully!");
      localStorage.removeItem('cart');
      setShowCart(false); // Close the cart after successful order
    })
    .catch((error) => {
      console.error("Error placing order:", error.message);
      alert("Failed to place order. Please try again.");
    });
};

  return (
    <>
      <section className='cart-outer'>
        <div className='cart-inner'>

          <button className='close-cart float-end' onClick={() => setShowCart(false)}><i class="fa-solid fa-xmark"></i></button>

          <div className="d-flex justify-content-center mt-5 mb-4">
            <hr className="shop-hr" />
            <h4 className="px-3 text-center text-nowrap">Products in Cart</h4>
            <hr className="shop-hr" />
          </div>

          <div className='cart-product-area'>
            {cart.length > 0 ?
              (
                cart.map((item) => (
                  <AddtoCart key={item.id} selectedProduct={item} removeCart={removeCart} onQuantityChange={quantityChange} />
                ))
              ) :
              (
                <p>🙄 No Products in cart. Add Now!</p>
              )}
          </div>

          <div className='pay-area'>
            <h6 className='mt-2'>Total Price: ₹ {totalPrice.toFixed(2)}</h6>
            <button className='pay-btn' onClick={handleOrder}>Proceed to Pay</button>
          </div>

        </div>
      </section>
    </>
  )
}

export default Cart
