import React, { useState, useEffect } from 'react'

const AdminOrders = ({ userDetails }) => {

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [deliveryStatus, setDeliveryStatus] = useState({});


    const fetchOrders = async () => {
        try {
            const response = await fetch('http://localhost:3002/api/admin/orders');
            const data = await response.json();

            if (data.orders) {
                const sortedOrders = data.orders.sort((a, b) => new Date(b.orderDate) - new Date(a.orderDate));
                setOrders(data.orders);
            } else {
                console.error('No orders found');
            }
        } catch (error) {
            console.error('Error fetching orders:', error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOrders();
    }, []); // Fetch orders once when the component mounts


    const calculateTotalAmount = (orderItems) => {
        return orderItems.reduce(
            (total, item) => total + item.order_price,
            0
        );
    };

    // Toggle the visibility of the delivery status form
    const toggleDeliveryStatus = (orderId) => {
        setDeliveryStatus((prev) => ({
            ...prev,
            [orderId]: !prev[orderId], // Toggle delivery visibility for the clicked order
        }));

    };


    // Handle form submission to update delivery status
    const handleUpdateStatus = async (event, orderId) => {
        event.preventDefault();
        const newStatus = event.target.status.value;
    
        try {
            const response = await fetch(`http://localhost:3002/api/admin/orders/${orderId}/status`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ status: newStatus }), // Send the updated status
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to update order status');
            }
    
            const updatedOrder = await response.json();
    
            // Update the local state with the new status
            setOrders((prevOrders) =>
                prevOrders.map((order) =>
                    order.orderId === updatedOrder.orderId ? { ...order, status: updatedOrder.status } : order
                )
            );
    
            // Close the delivery status form after update
            setDeliveryStatus((prev) => ({ ...prev, [orderId]: false }));
            alert('Status Updated Successfully');
            window.location.reload();
    
        } catch (error) {
            console.error('Error updating order status:', error);
            setError(error.message);
        }
    };


    return (
        <>
            <section className='py-5 bg-light'>
                <div className='container'>

                    <div className="d-flex justify-content-center">
                        <hr className="shop-hr" />
                        <h4 className="px-3 text-center text-nowrap">Order Records History</h4>
                        <hr className="shop-hr" />
                    </div>


                    <div className="order-list mt-5">
                        {orders.length === 0 ? (
                            <p>No orders found for this phone number.</p>
                        ) : (
                            orders.map((order) => (
                                <div key={order.orderId} className="order-item my-4">

                                    <div className='row'>

                                        <div className='col-lg-9 col-md-8 col-sm-6 col-12'>
                                            <h5>Order ID #00{order.orderId}</h5>

                                            <p className='para-text text-muted mb-0 text-capitalize'>Customer Name: {order.userName}</p>
                                            <p className='para-text text-muted mb-0'>Order Date: {new Date(order.orderDate).toLocaleDateString()}</p>
                                            <p className='para-text text-muted text-capitalize'>Delivery Status: {order.status}</p>
                                        </div>
                                        {/* left section ends */}

                                        <div className='col-lg-3 col-md-4 col-sm-6 col-12 text-end'>
                                            {/* <button className='pay-btn' onClick={() => toggleDeliveryStatus(order.orderId)} >Update Delivery <i class="fa-solid fa-truck-fast"></i></button> */}

                                            {!deliveryStatus[order.orderId] && (
                                                <button
                                                    className='pay-btn'
                                                    onClick={() => toggleDeliveryStatus(order.orderId)}
                                                >
                                                    Update Delivery <i className="fa-solid fa-truck-fast"></i>
                                                </button>
                                            )}

                                            {deliveryStatus[order.orderId] && (
                                                <>
                                                    <form className='bg-white' onSubmit={(event) => handleUpdateStatus(event, order.orderId)}>
                                                        {/* You can add form fields here to update delivery status */}
                                                        <select className='login-input' name='status' defaultValue={order.status}>
                                                            <option className="login-input" value="" selected disabled>Status</option>
                                                            <option className="login-input" value="Pending">Pending</option>
                                                            <option className="login-input" value="In Transit">In Transit</option>
                                                            <option className="login-input" value="Shipped">Shipped</option>
                                                            <option className="login-input" value="Delivered">Delivered</option>
                                                        </select>

                                                        <button type="submit" className='pay-btn'>Update Status</button>
                                                    </form>
                                                </>
                                            )}
                                        </div>
                                        {/* right section ends */}

                                    </div>
                                    {/* row ends */}

                                    <h6 className='mt-4'>Order Details:</h6>

                                    <div className='table-responsive rounded-2'>
                                        <table className='w-100 order-item-table'>
                                            <tr>
                                                <th>Product Name</th>
                                                <th>Quantity</th>
                                                <th>Price</th>
                                            </tr>

                                            {order.orderItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.order_name}</td>
                                                    <td>(Qty: {item.order_qty})</td>
                                                    <td>₹ {item.order_price}</td>
                                                </tr>
                                            ))}

                                        </table>
                                    </div>
                                    {/* table div ends */}

                                    <h6 className='mt-2'>Order Total: {calculateTotalAmount(order.orderItems).toFixed(2)}</h6>

                                </div>
                            ))
                        )}
                    </div>


                </div>
            </section>
        </>
    )
}

export default AdminOrders
