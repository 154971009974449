import React from 'react'
import CountUp from 'react-countup';

const Counter = () => {
  return (
    <>
            {/* Counter Section */}
            <section className='counter-bg'>
        <div className='container py-3 px-3'>

          <div className='row text-center'>

            <div className='col-lg-3 col-md-6 col-6 my-3   counter-area'>
              <CountUp end={7} duration={2} enableScrollSpy={true} className='counter-num' />
              <h6>Years of Experience</h6>
            </div>

            <div className='col-lg-3 col-md-6 col-6 my-3   counter-area'>
              <CountUp end={350} duration={2} enableScrollSpy={true} className='counter-num' />
              <h6>Project Done</h6>
            </div>

            <div className='col-lg-3 col-md-6 col-6 my-3   counter-area'>
              <CountUp end={24} duration={2} enableScrollSpy={true} className='counter-num' />
              <h6>Hours Support</h6>
            </div>

            <div className='col-lg-3 col-md-6 col-6 my-2'>
              <CountUp end={350} duration={2} enableScrollSpy={true} className='counter-num' />
              <h6>Active Clients</h6>
            </div>


          </div>
          {/* row ends */}

        </div>
      </section>
      {/* Counter Section ends */}
    </>
  )
}

export default Counter
