import React from 'react'
import Logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import Gallery1 from '../assets/gallery/1.jpg';
import Gallery2 from '../assets/gallery/2.jpg';
import Gallery3 from '../assets/gallery/3.jpg';
import Gallery4 from '../assets/gallery/4.jpg';
import Gallery5 from '../assets/gallery/5.jpg';
import Gallery6 from '../assets/gallery/6.jpg';
import ScrollToTop from 'react-scroll-to-top';


const Footer = () => {
    return (
        <>

        
      <ScrollToTop smooth className='scroll-to-top bg-red' color='white' viewBox="0 0 448 512"
        svgPath="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />

            {/* footer section */}
            <section className='bg-dark text-light pt-5 pb-4'>
                <div className='container px-3'>

                    <div className='row'>

                        <div className='col-lg-3 col-md-6 col-12 my-2'>
                            <img src={Logo} className='footer-logo' alt='footer logo' />
                            <p className='mt-4'>Dectwin Cam offers cutting-edge surveillance solutions designed to enhance security for homes and businesses.</p>
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 my-2'>
                            <hr className='sec-hr' />
                            <h4 className='fw-bold mb-4'>Quick Links</h4>
                            <p><Link className='footer-link' to='/'>Home</Link></p>
                            <p><Link className='footer-link' to='/about'>About</Link></p>
                            <p><Link className='footer-link' to='/contact'>Contact</Link></p>
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 my-2'>
                            <hr className='sec-hr' />
                            <h4 className='fw-bold mb-4'>Important</h4>
                            <p><Link className='footer-link' to='/shop'>Shop</Link></p>
                            <p><Link className='footer-link' to='/accounts'>Login</Link></p>
                            <p><Link className='footer-link' to='/faqs'>FAQs</Link></p>
                        </div>

                        <div className='col-lg-3 col-md-6 col-12 my-2 mobile-hide'>
                            <hr className='sec-hr' />
                            <h4 className='fw-bold mb-4'>Our Range</h4>
                            <div className='row'>

                                <div className='col-lg-4 col-4 my-2'>
                                    <img src={Gallery1} className='img-fluid' alt='Gallery' />
                                </div>

                                <div className='col-lg-4 col-4 my-2'>
                                    <img src={Gallery2} className='img-fluid' alt='Gallery' />
                                </div>

                                <div className='col-lg-4 col-4 my-2'>
                                    <img src={Gallery3} className='img-fluid' alt='Gallery' />
                                </div>

                                <div className='col-lg-4 col-4 my-2'>
                                    <img src={Gallery4} className='img-fluid' alt='Gallery' />
                                </div>

                                <div className='col-lg-4 col-4 my-2'>
                                    <img src={Gallery5} className='img-fluid' alt='Gallery' />
                                </div>

                                <div className='col-lg-4 col-4 my-2'>
                                    <img src={Gallery6} className='img-fluid' alt='Gallery' />
                                </div>

                            </div>
                            {/* inner row ends */}

                        </div>

                    </div>
                    {/* row ends */}

                </div>
            </section>
            {/* Footer section ends */}


            {/* Copyright Section */}
            <section className='bg-red py-1 text-center'>
                <div className='container pt-3 px-3'>
                    <p className='bg-dark text-light py-3 rounded'>Copyright &copy; Dectwin Cam. All Rights Reserved | Developed By 
                        <Link className='text-light text-decoration-none' to='https://dectwinservices.com' rel="noopener noreferrer" target='_blank'> Dectwin Services.</Link></p>
                </div>
            </section>
            {/* Copyright Section ends */}

        </>
    )
}

export default Footer
