import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
// import Signup from './Signup';
import axios from 'axios';

const Login = ({ setShowLogin }) => {

    // const [showSignup, setShowSignup] = useState(false);
    const [error, setError] = useState('');

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const handleLogin = async (e) => {
        e.preventDefault();

        // Reset error message
        setError('');

        try {
            const response = await axios.post('http://localhost:3002/api/login', { username, password }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            console.log(response.data);  // Handle success
            // You can store the token or user info in localStorage, Context API, etc.
            localStorage.setItem('user', JSON.stringify(response.data));
            // setShowLogin(false);
            alert('Login Successful');
                // navigate('/shop');

            if(response.data.username === `dectwincam` && response.data.id === 21){
                navigate('/accounts/admin', {state: response.data});
                window.location.reload();
            }

            else {
                navigate('/accounts/shop', {state: response.data});
                window.location.reload();
            }

        } catch (error) {
            console.error('Error during login:', error.response?.data || error.message);
            setError('Login failed. User not exist.');
        }
    };

    return (
        <>


            <section className='py-5 bg-light'>
                <div className='login-inner'>

                    <h4>Login to Purchase now!</h4>

                    <i className="fa-solid fa-user-tie user-login-icon mt-4"></i>

                    <form className='my-4 text-start' onSubmit={handleLogin}>
                        <div className="col-12">
                            <label for="inputName3">Username</label>
                            <input type="name" className="login-input" value={username} onChange={(e) => setUsername(e.target.value)} />
                        </div>

                        <div className="col-12">
                            <label for="inputPassword3">Password</label>
                            <input type="password" className="login-input" value={password} onChange={(e) => setPassword(e.target.value)} />
                        </div>

                        {/* Display error message if login fails */}
                        {error && <div className="error-message">{error}</div>}

                        <button type="submit" className="login-btn mt-4">Login</button>

                    </form>
                    {/* form ends */}

                    <h6 className='text-center'>Didn't have an account? <span className='toggle-link' onClick={() => setShowLogin(false)}>Sign In</span> here</h6>

                </div>
            </section>


            {/* {showSignup && <Signup setShowSignup={setShowSignup} />} */}

        </>
    )
}

export default Login
