import React from "react";
// import ShopHeader from "../shop/ShopHeader";
import WhyChoose from "../shop/WhyChoose";
// import Login from "../shop/Login";
import Banner1 from "../assets/shop-banner1.png";
import Banner2 from "../assets/shop-banner2.png";
import "swiper/css";
import "../Shop.css";
import TestimonialImg from "../assets/testimonial-img2.png";
import ShopBannerImg from "../assets/shop-bannerbg1.png";
import CatImg1 from "../assets/category/1.jpg";
import CatImg2 from "../assets/category/2.jpg";
import CatImg3 from "../assets/category/3.jpg";
import CatImg4 from "../assets/category/4.jpg";
import CatImg5 from "../assets/category/5.jpg";
import CatImg6 from "../assets/category/6.jpg";
import { useLocation } from "react-router-dom";
import ClientProducts from "../shop/ClientProducts";
import { useNavigate } from "react-router-dom";
// import Cart from '../cart/Cart'

const Shop = ({ userDetails, setShowCart, handleAddCart }) => {

  const location = useLocation();
  userDetails = location.state;

  const navigate = useNavigate();

  // const [showCart, setShowCart] = useState(false);
  // const [cart, setCart] = useState([]);

//   const handleAddCart = (product) => {
//     setCart((prevCart) => {
//       const productExists = prevCart.find((item) => item.id === product.id);
//       if (productExists) {
//         return prevCart.map((item) =>
//           item.id === product.id ? { ...item, quantity: item.quantity + 1 } : item
//         );
//       }
//       return [...prevCart, { ...product, quantity: 1 }];
//     });

//  setShowCart(true);
//   };

  // const handleRemoveCart = (productId) => {
  //   setCart((prevCart) => prevCart.filter((item) => item.id !== productId));
  // };

  return (
    <>

      {/* <ShopHeader userDetails={userDetails} setShowCart={setShowCart} /> */}

      {/* Slider Section */}
      <div id="carouselExampleIndicators" class="carousel slide carousel-fade" data-bs-ride="carousel" data-bs-interval="2000">
        {/* <div class="carousel-indicators mt-3">
          <button type="button" className="shop-ind-btn active" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" className="shop-ind-btn" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div> */}

        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={Banner1} class="d-block w-100" alt="Slider" />
          </div>
          <div class="carousel-item">
            <img src={Banner2} class="d-block w-100" alt="Slider" />
          </div>
        </div>
      </div>
      {/* Slider Section ends */}


      {/* Category Section */}
      <section className="bg-light py-5">
        <div className="container">

          <div className="d-flex justify-content-center">
            <hr className="shop-hr" />
            <h4 className="px-3 text-center text-nowrap">Shop By Category</h4>
            <hr className="shop-hr" />
          </div>

          <div className="row mt-4 justify-content-center text-center">
            <div className="col-lg-2 col-md-3 col-4 my-2 shop-cat-area" onClick={() => navigate('/shop/category')}>
              <img
                src={CatImg1}
                className="shop-cat-img img-fluid"
                alt="Category"
              />
              <h6 className="fw-400 mt-4">HD Camera</h6>
            </div>

            <div className="col-lg-2 col-md-3 col-4 my-2 shop-cat-area" onClick={() => navigate('/shop/category')}>
              <img
                src={CatImg2}
                className="shop-cat-img img-fluid"
                alt="Category"
              />
              <h6 className="fw-400 mt-4">IP Camera</h6>
            </div>

            <div className="col-lg-2 col-md-3 col-4 my-2 shop-cat-area" onClick={() => navigate('/shop/category')}>
              <img
                src={CatImg3}
                className="shop-cat-img img-fluid"
                alt="Category"
              />
              <h6 className="fw-400 mt-4">DVR/ NVR</h6>
            </div>

            <div className="col-lg-2 col-md-3 col-4 my-2 shop-cat-area" onClick={() => navigate('/shop/category')}>
              <img
                src={CatImg4}
                className="shop-cat-img img-fluid"
                alt="Category"
              />
              <h6 className="fw-400 mt-4">POE Switch/ SMPS</h6>
            </div>

            <div className="col-lg-2 col-md-3 col-4 my-2 shop-cat-area" onClick={() => navigate('/shop/category')}>
              <img
                src={CatImg5}
                className="shop-cat-img img-fluid"
                alt="Category"
              />
              <h6 className="fw-400 mt-4">Wires/ Cables</h6>
            </div>

            <div className="col-lg-2 col-md-3 col-4 my-2 shop-cat-area" onClick={() => navigate('/accounts/shop/category')}>
              <img
                src={CatImg6}
                className="shop-cat-img img-fluid"
                alt="Category"
              />
              <h6 className="fw-400 mt-4">Camera Accessories</h6>
            </div>
          </div>
          {/* row ends */}
        </div>
      </section>

      {/* Category Section Ends */}

      {/* Banner section */}
      <section className="banner-bg">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-8 col-md-9 col-9 my-2">
              <div className="banner-heading mx-auto">
                Live video monitoring Solutions
              </div>
              <h2 className="mt-3 text-light text-uppercase">Flat 20% Off</h2>
            </div>

            <div className="col-lg-4 col-md-3 col-3">
              <img
                src={ShopBannerImg}
                className="shop-bannerimg"
                alt="Shop Banner Product"
              />
            </div>
          </div>
          {/* row ends */}
        </div>
      </section>
      {/* Banner section ends */}

      {/* Latest Product Section */}
      <section className="bg-light py-5">
        <div className="container d-flex justify-content-center">
          <hr className="shop-hr" />
          <h4 className="px-3 text-center text-nowrap">
            Shop Like a Pro<h6>Explore current top picks!</h6>
          </h4>
          <hr className="shop-hr" />
        </div>

        <div className="row mt-4">
          <div className="col-lg-4 col-md-4 col-12 my-2 px-0">
            <img
              src={TestimonialImg}
              className="img-fluid"
              alt="Testimonial"
            />
          </div>

          <div className="col-lg-8 col-md-8 col-12 order-first order-lg-last">
            <ClientProducts addToCart={handleAddCart} />
          </div>
        </div>
        {/* row ends */}
      </section>
      {/* Latest Product Section ends */}      
      <WhyChoose />

      {/* {showCart && <Cart cart={cart} setShowCart={setShowCart} removeCart={handleRemoveCart} />} */}

    </>
  );
};

export default Shop;
