import React, { useEffect, useState } from 'react'
import axios from 'axios';
import EditProduct from './EditProduct';

const AdminProducts = () => {

    const [products, setProducts] = useState([]);
    const [showEditProduct, setShowEditProduct] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axios.get('http://localhost:3002/api/products');
                // console.log('fetched products', response.data);
                setProducts(response.data);
            }

            catch (err) {
                console.log('Failed to fetch products', err.response.data);
            }
        };

        fetchProducts();
    }, []);


    const handleEditProduct = (product) => {
        setSelectedProduct(product);
        setShowEditProduct(true);
    }

    const handleDeleteProduct = async (product) => {

    // (<section></section>)

        try {
            // Send a DELETE request to the backend
            await axios.delete(`http://localhost:3002/api/products/${product.id}`);

            // Remove the deleted product from the state
            setProducts(products.filter(p => p.id !== product.id));

            console.log('Product deleted successfully!');
        } catch (err) {
            console.log('Failed to delete product', err.response.data);
        }
    };

    return (
        <>
            <section className='bg-light py-5'>
                <div className='container-fluid'>

                    <div className="d-flex justify-content-center">
                        <hr className="shop-hr" />
                        <h4 className="px-3 text-center text-nowrap">In Store Products</h4>
                        <hr className="shop-hr" />
                    </div>

                    <div className='row mt-4'>

                        {
                            products.length === 0 ? (<p>No Products available yet.</p>) :
                            (
                                    products.map(product =>
                                        (
                                        <div className='col-lg-3 col-md-4 col-6 my-2' key={product.id}>
                                            <div className="shop-product">
                                                <img
                                                    src={product.image}
                                                    className="img-fluid"
                                                    alt="Latest Product"
                                                />
                                                <h6 className="shop-product-title my-4">{product.name}</h6>
                                                <p className='shop-product-detail'>Category: {product.category}</p>
                                                <p className='shop-product-detail'>Stock: {product.stock}</p>
                                                <p className='shop-product-detail'>Price:&nbsp;
                                                    <span className="dis-price">{product.discount_price}</span>
                                                    <span className="org-price">{product.original_price}</span>
                                                </p>

                                                <p className='shop-product-detail'>Description: {product.description}</p>

                                                <div className='d-flex'>
                                                    <button className="add-cart mt-2" onClick={() => handleEditProduct(product)}>Edit <i className="fa-solid fa-pen-to-square"></i></button>
                                                    <button className="add-cart mt-2 ms-1" onClick={() => handleDeleteProduct(product)}>Delete <i classname="fa-solid fa-trash-can"></i></button></div>
                                            </div>
                                        </div>
                                    )
                                )
                                )}

                    </div>
                    {/* row ends */}

                </div>
            </section>

            {showEditProduct && <EditProduct setShowEditProduct={setShowEditProduct} selectedProduct={selectedProduct} setProducts={setProducts} />}
        </>
    )
}

export default AdminProducts
