import React from 'react'

const Copyright = () => {
  return (
    <>
      {/* copyright section */}
      <section className='bg-black py-1'>
        <div className='container text-center'>
        <p className='text-light pt-2'>Copyright &copy; Dectwin Cam. All Rights Reserved | Developed By Dectwin Services.</p>
        </div>
      </section>
            {/* copyright section ends */}

    </>
  )
}

export default Copyright
