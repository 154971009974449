import React from 'react'
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.png';

const Header = () => {
  return (
    <>


      {/* Header Section */}
      <section className='header-bg'>
        <div className='container d-flex justify-content-between'>

          <div className='d-inline-flex'>
            <p className='mobile-hide mt-3'><i className='fa-regular fa-envelope header-icon'></i>info@dectwincam.com</p>&nbsp;&nbsp;&nbsp;
            <p className='mobile-hide mt-3'><i className='fa-solid fa-phone-flip header-icon'></i>9454722998</p>

            <div className='mobile-show mt-2 mb-1'>
              <i className="fa-brands nav-icon fa-facebook-f"></i>
              <i className="fa-brands nav-icon fa-instagram"></i>
              <i className="fa-brands nav-icon fa-youtube"></i>
            </div>

          </div>

          <div className='d-inline-flex'>
            <p className='mt-3 mobile-hide'><i className='fa-regular fa-clock header-icon'></i>Mon - Sat 10:00 - 8: 00</p>&nbsp;&nbsp;&nbsp;
            <Link className='header-link' to='/accounts'>Login Now</Link>
          </div>
        </div>
      </section>
      {/* Header Section ends */}


      {/* Navbar Section */}
      <nav className="navbar navbar-expand-lg navbar-light py-0 sticky-top">
        <div className="container">
          <Link className="navbar-brand order-first" to="/"><img src={Logo} className='header-logo' alt='Site Logo' /></Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse order-sm-last order-lg-first" id="navbarSupportedContent">
            <ul className="navbar-nav mb-2 mb-lg-0 ms-auto text-end">
              <li className="nav-item py-2">
                <Link className="nav-text active" aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item py-2">
                <Link className="nav-text" to="/about">About</Link>
              </li>
              <li className="nav-item py-2">
                <Link className="nav-text" to="/shop">Shop</Link>
              </li>
              <li className="nav-item py-2">
                <Link className="nav-text" to="/contact">Contact</Link>
              </li>
            </ul>
          </div>

          <div className='mobile-hide ms-auto'>
            <i className="fa-brands nav-icon fa-facebook-f"></i>
            <i className="fa-brands nav-icon fa-instagram"></i>
            <i className="fa-brands nav-icon fa-youtube" style={{ padding: '0.8em 0.7em' }}></i>
          </div>

        </div>
      </nav>
      {/* Navbar Section ends */}

    </>
  )
}

export default Header
