import React, {useEffect, useState} from 'react'
import axios from 'axios';

const EditProduct = ({ setShowEditProduct, selectedProduct, setProducts }) => {

    const [formData, setFormData] = useState({
        name: '',
        category: '',
        stock: '',
        original_price: '',
        discount_price: '',
        image: '',
        description: ''
    });

    useEffect(() => {
        if(selectedProduct){
            setFormData({
                name: selectedProduct.name,
                category: selectedProduct.category,
                stock: selectedProduct.stock,
                original_price: selectedProduct.original_price,
                discount_price: selectedProduct.discount_price,
                image: selectedProduct.image,
                description: selectedProduct.description
            });
        }
    }, [selectedProduct]);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0]  // Store the file object here
    });
  };

  // Handle form submission (updating product)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const updateFormData = new FormData();
    updateFormData.append('name', formData.name);
    updateFormData.append('category', formData.category);
    updateFormData.append('stock', formData.stock);
    updateFormData.append('original_price', formData.original_price);
    updateFormData.append('discount_price', formData.discount_price);
    updateFormData.append('description', formData.description);

    // If a new image is selected, append it to the FormData object
    if (formData.image) {
      updateFormData.append('image', formData.image);
    }

    try {
      // Send updated product data to the backend
      const response = await axios.put(
        `http://localhost:3002/api/products/${selectedProduct.id}`,
        updateFormData, {
            headers: {'Content-Type': 'multipart/form-data'}
        }
      );

      // Update products in the state
      setProducts((prevProducts) =>
        prevProducts.map((product) =>
          product.id === selectedProduct.id ? response.data : product
        )
      );
      
      // Close the edit modal
      setShowEditProduct(false);
      window.location.reload();

    } catch (err) {
      console.error('Error updating product:', err);
    }
  };


  return (
    <>
            <section className='login-outer' onClick={() => setShowEditProduct(false)}>
        <div className='login-inner' onClick={(e) => e.stopPropagation()}>

          <h4>Edit Product Details</h4>

          <form className='my-4 text-start row' onSubmit={handleSubmit}>
            <div className="col-12">
              <label for="inputName3">Product Name</label>
              <input type="text" className="login-input" name='name' value={formData.name} onChange={handleChange} required />
            </div>

            <div className="col-md-6 col-6">
              <label for="inputCategory3">Select Category</label>
              <select className="login-input" name='category' value={formData.category} onChange={handleChange} required>
                <option className="login-input" value="" selected disabled>Choose from</option>
                <option className="login-input" value="HD Camera">HD Camera</option>
                <option className="login-input" value="IP Camera">IP Camera</option>
                <option className="login-input" value="DVR/ NVR">DVR/ NVR</option>
                <option className="login-input" value="POE Swtich/ SMPS">POE Swtich/ SMPS</option>
                <option className="login-input" value="Wires/ Cables">Wires/ Cables</option>
                <option className="login-input" value="Camera Accessories">Camera Accessories</option>
              </select>
            </div>

            <div className='col-md-6 col-6'>
            <label for="inputStock3">Select Stock</label>
              <select className="login-input" name='stock' value={formData.stock} onChange={handleChange} required>
                <option className="login-input" value="" selected disabled>Choose from</option>
                <option className="login-input" value="In Stock">In Stock</option>
                <option className="login-input" value="Out of Stock">Out of Stock</option>
              </select>
            </div>
  
            <div className="col-md-6 col-6">
              <label for="inputOrgPrice3">Original Price</label>
              <input type="number" className="login-input" name='original_price' value={formData.original_price} onChange={handleChange} required />
            </div>

            <div className="col-md-6 col-6">
              <label for="inputDisPrice3">Discount Price</label>
              <input type="number" className="login-input" name='discount_price' value={formData.discount_price} onChange={handleChange} required />
            </div>

            <div className='col-12'>
              <label for="formFile" >Upload Product Image</label>
              <input class="login-input" type="file" id="formFile" name='image' onChange={handleFileChange} />
            </div>

            <div className="col-12">
              <label for="inputDescription3">Description</label>
              <input type="text" className="login-input" style={{ height: '6em' }} name='description' value={formData.description} onChange={handleChange} required />
            </div>

            {/* Display error message if login fails */}
            {/* {error && <div className="error-message">{error}</div>} */}

            <div className='col-12'>
              <button type="submit" className="login-btn mt-4">Update Product</button>
            </div>

          </form>
          {/* form ends */}


        </div>
      </section>

    </>
  )
}

export default EditProduct
